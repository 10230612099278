import styled from "styled-components"

export const ShieldImgContainer = styled.div`
  width: min(450px, 100%);
  height: 450px;
  margin: auto;
  margin-top: 1rem;

  @media screen and (max-width: 768px) {
    width: min(221px, 100%);
    height: 224px;
    margin-top: 100px;
  }
`

export const ThreatItemContainer = styled.div`
  max-width: 175px;
  @media screen and (max-width: 768px) {
    max-width: 72px;
  }
`

export const BurglaryTheft = styled(ThreatItemContainer)`
  position: absolute;
  top: 8rem;
  left: 14rem;

  @media screen and (max-width: 768px) {
    top: 4.5rem;
    left: 1rem;
  }
`

export const RiotsStrikes = styled(ThreatItemContainer)`
  position: absolute;
  bottom: 4.5rem;
  right: 22.5rem;

  @media screen and (max-width: 768px) {
    top: 2rem;
    left: 42%;
    right: 40%;
    bottom: auto;
  }
`

export const Explosions = styled(ThreatItemContainer)`
  position: absolute;
  top: 8rem;
  right: 20rem;

  @media screen and (max-width: 768px) {
    bottom: 4.5rem;
    left: 1rem;
    top: auto;
    right: auto;
  }
`

export const FireFloods = styled(ThreatItemContainer)`
  position: absolute;
  top: 20rem;
  left: 24rem;

  @media screen and (max-width: 768px) {
    top: 4.5rem;
    left: auto;
    right: 1rem;
  }
`

export const MuchMore = styled(ThreatItemContainer)`
  position: absolute;
  top: 15rem;
  right: 10rem;

  @media screen and (max-width: 768px) {
    bottom: 4.5rem;
    top: auto;
    right: 1rem;
  }
`

export const ThreatIconContainer = styled.div`
  width: 98.35px;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: min(60px, 100%);
  }
`