import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Section, SectionWrapper, Text } from "../../core/commonExports"
import { BurglaryTheft, Explosions, FireFloods, MuchMore, RiotsStrikes, ShieldImgContainer, ThreatIconContainer } from "../../ProtectionsAgainstRisks/SharedComponents"

const ProtectAgainstRisks = ({
  desktopStyles={background: "#fff"},
  mobileStyles={backgronud: "#F6F9FF"},
  style={}
}) => {
  return (
    <Section style={style} desktopStyles={desktopStyles} mobileStyles={mobileStyles}>
      <SectionWrapper>
        <ShieldImgContainer>
          <StaticImage 
            src="../../../assets/images/protection-from-threats-shield.webp"
            alt="get protection from 15+ risks"
            loading="lazy"
            placeholder="blurred"
            width={502}
            height={502}
          />
        </ShieldImgContainer>
        <BurglaryTheft>
          <ThreatIconContainer>
            <StaticImage 
              src="../../../assets/images/threat-icons-mlp/burglary-theft.webp"
              alt="burglary-and-theft"
              width={98}
              height={98}
              loading="lazy"
              placeholder="blurred"
            />
          </ThreatIconContainer>
          <Text fontSize="16px" mfontSize="11px" lineHeight="22.68px" mlineHeight="12px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{margin: "0", marginTop: "1rem"}} mobileStyles={{margin: "0", marginTop: "0.5rem"}} color="#16186E">Burglary and Theft</Text>
        </BurglaryTheft>
        <FireFloods>
          <ThreatIconContainer>
            <StaticImage 
              src="../../../assets/images/threat-icons-mlp/fire.webp"
              alt="fire-and-flood"
              width={98}
              height={98}
              loading="lazy"
              placeholder="blurred"
            />
          </ThreatIconContainer>
          <Text fontSize="16px" mfontSize="11px" lineHeight="22.68px" mlineHeight="12px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{margin: "0", marginTop: "1rem"}} mobileStyles={{margin: "0", marginTop: "0.5rem"}} color="#16186E">Fire and Flood</Text>
        </FireFloods>
        <Explosions>
          <ThreatIconContainer>
            <StaticImage 
              src="../../../assets/images/threat-icons-mlp/explosions.webp"
              alt="explosions-and-earthquakes"
              width={98}
              height={98}
              loading="lazy"
              placeholder="blurred"
            />
          </ThreatIconContainer>
          <Text fontSize="16px" mfontSize="11px" lineHeight="22.68px" mlineHeight="12px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{margin: "0", marginTop: "1rem"}} mobileStyles={{margin: "0", marginTop: "0.5rem"}} color="#16186E">Explosions and Earthquakes</Text>
        </Explosions>
        <RiotsStrikes>
          <ThreatIconContainer>
            <StaticImage 
              src="../../../assets/images/threat-icons-mlp/riots.webp"
              alt="riots-and-strikes"
              width={98}
              height={98}
              loading="lazy"
              placeholder="blurred"
            />
          </ThreatIconContainer>
          <Text fontSize="16px" mfontSize="11px" lineHeight="22.68px" mlineHeight="12px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{margin: "0", marginTop: "1rem"}} mobileStyles={{margin: "0", marginTop: "0.5rem"}} color="#16186E">Riots and Strikes</Text>
        </RiotsStrikes>
        <MuchMore>
          <ThreatIconContainer>
            <StaticImage 
              src="../../../assets/images/threat-icons-mlp/lightning.webp"
              alt="and-much-more"
              width={98}
              height={98}
              loading="lazy"
              placeholder="blurred"
            />
          </ThreatIconContainer>
          <Text fontSize="16px" mfontSize="11px" lineHeight="22.68px" mlineHeight="12px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{margin: "0", marginTop: "1rem"}} mobileStyles={{margin: "0", marginTop: "0.5rem"}} color="#16186E">And much more</Text>
        </MuchMore>
      </SectionWrapper>
    </Section>
  )
}

export default ProtectAgainstRisks